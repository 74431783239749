import {hydrate} from "@/utils/form";

export default class SubscriptionPlanPricePaymentToken {
  paymentToken = null;
  quantity = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }
}
