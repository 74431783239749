import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const URI_SUBSCRIPTION_PLAN = "/clubs/subscription-plans";

export const getSubscriptionPlans = (itemsPerPage = 500, page = 1, search, enabled) => {
  let url = URI_SUBSCRIPTION_PLAN + '?club.id=' + clubId;

  if ("undefined" === typeof enabled) {
    url += '&enabled=true';
  } else {
    if (null !== enabled) {
      url += '&enabled=' + enabled;
    }
  }

  if (itemsPerPage) {
    url += '&itemsPerPage=' + itemsPerPage;
  }
  if (page) {
    url += '&page=' + page;
  }
  if (search) {
    url += '&search=' + search;
  }
  return _get(url);
}

export const postSubscriptionPlan = (subscriptionPlan) => _post(URI_SUBSCRIPTION_PLAN, subscriptionPlan);

export const getSubscriptionPlan = (id) => _get(URI_SUBSCRIPTION_PLAN + '/' + id);

export const putSubscriptionPlan = (id, data) => _put(URI_SUBSCRIPTION_PLAN + '/' + id, data);

export const deleteSubscriptionPlan = (id) => _delete(URI_SUBSCRIPTION_PLAN + '/' + id);
