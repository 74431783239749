import {hydrate} from "@/utils/form";
import SubscriptionPlanPrice from "@/classes/doinsport/SubscriptionPlanPrice";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import Vue from 'vue';

export default class SubscriptionPlan {
  id = null;
  name = null;
  description = null;
  automaticRenewal = false;
  color = "#0b9df9";
  enabled = true;
  fromDate = null;
  toDate = null;
  minDelayToAllowBooking = null;
  maxDelayToAllowBookingCancellation = null;
  validityPeriodicityType = null;
  networkSharingFor = null;
  networkSharingSelectedClubs = [];
  sendExpireSoonMessageEnabled = true;
  networkEditable = true;
  validityDurationCount = null;
  commitmentDurationCount = null;
  commitmentPeriodicityType = 'month';
  prices = []
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
      if (options.cast) {
        this.serialize(object);
        this.castToInt(object);
        this.setCommitmentPeriodicityType(object, 'month');
        this.updateClubNetworkList(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  updateValidityDates(object) {
    if (this.isNotNullable(object.fromDate) && this.isNotNullable(object.toDate)) {
      this.fromDate = Vue.prototype.$moment(object.fromDate);

      this.fromDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });

      this.toDate = Vue.prototype.$moment(object.toDate);

      this.toDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    }
  }

  isNotNullable(val) {
    return isNotUndefinedAndNotNull(val) && val !== '';
  }

  deserialize(object) {
    this.castToString(object);

    hydrate(this, object);

    let deserializedPrices = [];

    for (let price of object.prices) {
      const deserializedPrice = new SubscriptionPlanPrice(price, {deserialize: true});

      deserializedPrices.push(deserializedPrice);
    }

    object.prices = deserializedPrices;
  }

  castToInt() {
    this.commitmentDurationCount = this.commitmentDurationCount ? parseInt(this.commitmentDurationCount) : null;

    this.validityDurationCount = this.validityDurationCount ? parseInt(this.validityDurationCount) : null;

    this.minDelayToAllowBooking = this.minDelayToAllowBooking ? (parseInt(this.minDelayToAllowBooking) * 3600) : null;

    this.maxDelayToAllowBookingCancellation = this.maxDelayToAllowBookingCancellation ? (parseInt(this.maxDelayToAllowBookingCancellation) * 3600) : null;

    let serializedPrices = []

    for (let price of this.prices) {
      serializedPrices.push(new SubscriptionPlanPrice(price, {cast: true}));
    }

    this.prices = serializedPrices;
  }

  castToString(object) {
    object.validityDurationCount = object.validityDurationCount ? new String(object.validityDurationCount).toString() : null;
    object.minDelayToAllowBooking = object.minDelayToAllowBooking ? new String(object.minDelayToAllowBooking).toString() : null;
    object.maxDelayToAllowBookingCancellation = object.maxDelayToAllowBookingCancellation ? new String(object.maxDelayToAllowBookingCancellation).toString() : null;
  }

  setCommitmentPeriodicityType(object, value) {
    object.commitmentPeriodicityType = value;
  }

  updateClubNetworkList(object) {
    if (object.networkSharingFor !== 'selection')
      this.networkSharingSelectedClubs = [];
      object.networkSharingSelectedClubs = [];
  }
}
