<template>
  <div class="container-fluid">
    <b-card>
      <b-row
        no-gutters
        class="row-style"
      >
        <b-col
          :cols="getTopNavMenuCol()"
          class="mt-2 ml-3"
        >
          <d-body-nav-bar
            :tabs="$store.getters['menu/getTabs'].subscriptionsIndex"
            @tab-changed="onTabChanged"
          />
        </b-col>
        <b-col
          v-if="$store.getters['layout/getInnerWidth'] > 1332"
          class="mt-3 text-right mr-2"
          cols="4"
        >
          <d-button
            :text="'views.subscriptions.index.new-subscription'"
            :class="'ml-2 d-btn btn d-btn-primary font-text-title'"
            icon="fa fa-plus mr-1"
            @on:button-click="$router.push({ name: 'subscription_new' })"
          />
        </b-col>
        <b-col v-if="$store.getters['layout/getInnerWidth'] > 1332" class="mt-3 mr-3">
          <div class="has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              v-model="filter"
              @keyup="manualFilter"
              :placeholder="$t('components.doinsport.table.search')"
            >
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="$store.getters['layout/getInnerWidth'] < 1332"
      >
        <b-col :class="getColsClasses()" :cols="getBtnsCols()">
          <d-button
            :text="'views.subscriptions.index.new-subscription'"
            :class="'ml-2 d-btn btn btn-full-width d-btn-primary font-text-title'"
            icon="fa fa-plus-o mr-1"
            @on:button-click="$router.push( {name: 'subscription_new'})"
          />
        </b-col>
        <b-col
          :class="getColsClasses()"
          :cols="getBtnsCols()"
        >
          <div class="btn-full-width has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              v-model="filter"
              @keyup="manualFilter"
              :placeholder="$t('components.doinsport.table.search')"
            >
          </div>
        </b-col>
      </b-row>
      <d-table
        identifier="subscription"
        :tablefields="tableFields"
        :items="items"
        :per-page="itemsPerPage"
        :totalRows="totalRows"
        :current-page="currentPage"
        :is-busy="isBusy"
        :tr-cursor-pointer="false"
        :per-page-enabled="true"
        @filter:from-server="search"
        @on:data-table-btn:edit="onSubscriptionUpdate"
        @on:subscription:rar="onSubscriptionRar"
        @on:subscription:unrar="onSubscriptionUnRar"
        @on:subscription:delete="onSubscriptionDelete"
        @current-page:update="onCurrentPageUpdate"
        @per-page:update="onPerPageUpdate"
      >
      </d-table>
    </b-card>
  </div>
</template>
<script>
import {
  deleteSubscriptionPlan,
  getSubscriptionPlans,
  putSubscriptionPlan
} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import SubscriptionPlan from "@/classes/doinsport/SubscriptionPlan";

export default {
  components: {},
  data: () => ({
    items: [],
    credits: [],
    filter: '',
    isBusy: false,
    isBusyCredits: false,
    itemsPerPage: 10,
    totalRowsCredits: 10,
    currentPage: 1,
    currentPageCredits: 1,
    totalRows: 1,
    isArchived: false,
  }),
  watch: {
    isArchived: function () {
      this.loadSubscriptions(this.itemsPerPage, this.currentPage, '');
    }
  },
  computed: {
    currentClub() {
      return this.$store.getters['auth/currentClub'];
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {key: "name", label: this.$t("views.subscriptions.index.table.name"), sortable: true},
        {key: "description", label: this.$t("views.subscriptions.index.table.description")},
        {
          key: "subscriptionPrices",
          class: this.alignCellsToLeft,
          label: this.$t("views.subscriptions.index.table-credit.price"),
          sortable: true
        },
        {
          key: "validity",
          class: this.alignCellsToLeft,
          label: this.$t("views.subscriptions.index.table-credit.credit-validity")
        },
        {
          key: "automaticRenewal",
          class: this.alignCellsToLeft,
          label: this.$t("views.subscriptions.index.table.automatic-renewal")
        },
        {key: "actions", label: this.$t("general.actions.actions")},
      ];
    },
  },
  methods: {
    onTabChanged(scope) {
      this.filter = '';
      this.isArchived = (scope.name === 'views.subscriptions.rar' && scope.active);
    },
    onSubscriptionUpdate(scope) {
      this.$router.push({name: 'subscription_update', params: {id: scope.id}})
    },
    onSubscriptionDelete(scope) {
      deleteSubscriptionPlan(scope.id)
        .then((response) => {
          this.loadSubscriptions(this.itemsPerPage, this.currentPage, '');
        })
      ;
    },
    onSubscriptionRar(scope) {
      putSubscriptionPlan(scope.id, {enabled: false})
        .then((response) => {
          this.loadSubscriptions(this.itemsPerPage, this.currentPage, '');
        })
      ;
    },
    onSubscriptionUnRar(scope) {
      putSubscriptionPlan(scope.id, {enabled: true})
        .then((response) => {
          this.loadSubscriptions(this.itemsPerPage, this.currentPage, '');
        })
      ;
    },
    getCardCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1332 && width >= 1185) {
        return 'col-md-4';
      } else if (width < 1185) {
        return 'col-md-6';
      } else {
        return 'col-md-3';
      }
    },
    getColsClasses() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-2';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '6';
      }
    },
    getBtnsCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '3';
      } else {
        return '6';
      }
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search(this.filter);
      }, 400);
    },
    search(filter) {
      this.loadSubscriptions(this.itemsPerPage, this.currentPage, filter);
    },
    onCurrentPageUpdate(page) {
      this.loadSubscriptions(this.itemsPerPage, page, this.filter);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadSubscriptions(itemsPerPage, this.currentPage, this.filter);
    },
    loadSubscriptions(itemsPerPage, currentPage, search) {
      this.isBusy = true;
      this.items = [];
      const filterTag = !this.isArchived;

      getSubscriptionPlans(itemsPerPage, currentPage, search, filterTag)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.itemsPerPage = itemsPerPage;

          for (const subscriptionPlan of response.data['hydra:member']) {

            const serializedSubscriptionPlan = new SubscriptionPlan(subscriptionPlan, {serialize: true});

            serializedSubscriptionPlan.automaticRenewal = serializedSubscriptionPlan.automaticRenewal ? this.$t('general.actions.yes') : this.$t('general.actions.no');

            if (serializedSubscriptionPlan.description === null) {
              serializedSubscriptionPlan.description = '-';
            }

            if (serializedSubscriptionPlan.prices.length === 0) {
              Object.assign(serializedSubscriptionPlan, {subscriptionPrices: this.$t('views.subscriptions.index.no-prices')})
            } else if (serializedSubscriptionPlan.prices.length === 1) {
              let priceField =
                serializedSubscriptionPlan.prices[0].price / 100 +
                ' ' + this.$currency +
                ' / ' +
                ' ' + (serializedSubscriptionPlan.prices[0].dueDateGap === 1 ? '' : serializedSubscriptionPlan.prices[0].dueDateGap + ' ') +
                this.$t('general.actions.' + serializedSubscriptionPlan.prices[0].dueDateGapType);
              if (serializedSubscriptionPlan.prices[0].dueDateGapType === 'week') {
                priceField =
                  serializedSubscriptionPlan.prices[0].price / 100 +
                  ' ' + this.$currency +
                  ' / ' +
                  ' ' + (serializedSubscriptionPlan.prices[0].dueDateGap === 1 ? '' : serializedSubscriptionPlan.prices[0].dueDateGap + ' ') +
                  this.$t('general.actions.' + serializedSubscriptionPlan.prices[0].dueDateGapType + '.label') + '(s)';
              } else if (serializedSubscriptionPlan.prices[0].dueDateGapType === 'day') {
                priceField += '(s)';
              }
              Object.assign(serializedSubscriptionPlan, {subscriptionPrices: priceField})
            } else {
              Object.assign(serializedSubscriptionPlan, {subscriptionPrices: serializedSubscriptionPlan.prices.length})
            }
            if (serializedSubscriptionPlan.fromDate !== null && serializedSubscriptionPlan.toDate !== null) {
              Object.assign(serializedSubscriptionPlan, {validity: this.$moment(serializedSubscriptionPlan.fromDate).format('DD/MM/YYYY') + ' - ' + this.$moment(serializedSubscriptionPlan.toDate).format('DD/MM/YYYY')})
            } else {
              const multiple = (parseInt(serializedSubscriptionPlan.validityDurationCount) > 1 && serializedSubscriptionPlan.validityPeriodicityType !== 'month') ? this.$t('general.actions.multiple') : '';

              if (serializedSubscriptionPlan.validityPeriodicityType === 'week') {
                Object.assign(serializedSubscriptionPlan, {
                  validity: serializedSubscriptionPlan.validityDurationCount + ' ' +
                    this.$t('general.actions.week.label') + multiple
                })
              } else {
                Object.assign(serializedSubscriptionPlan, {
                  validity: serializedSubscriptionPlan.validityDurationCount + ' ' +
                    this.$t('general.actions.' + serializedSubscriptionPlan.validityPeriodicityType) + multiple
                })
              }
            }
            const deletableIcon = subscriptionPlan.club['@id'] === this.currentClub['@id'] ;

            const actionsArray = deletableIcon ? ['edit',serializedSubscriptionPlan.enabled ? 'rar': 'unrar', 'delete'] : ['edit'];

            Object.assign(serializedSubscriptionPlan, {actions: actionsArray});

            this.items.push(serializedSubscriptionPlan);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
  },
  mounted() {
    this.loadSubscriptions(this.itemsPerPage, this.currentPage, '');
  }
};
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
