import {hydrate} from "@/utils/form";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import SubscriptionPlanPricePaymentToken from "@/classes/doinsport/SubscriptionPlanPricePaymentToken";

export default class SubscriptionPlanPrice {
  id = null;
  label = null;
  price = 0;
  fees = 0;
  paymentCount = 1;
  club = JSON.parse(localStorage.getItem('selectedClub'));
  dueDateGap = 1;
  dueDateGapType = null;
  paymentTokens = [];
  onlineSubscribable = false;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
      if (options.cast) {
        this.serialize(object);
        this.castToInt();
        this.castToCents();
        this.serializePaymentTokens()
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  deserialize(object) {
    hydrate(this, object);
    this.castToCurrency(object);
    this.castToString(object);
  }

  castToInt() {
    this.paymentCount = this.paymentCount ? parseInt(this.paymentCount) : null;
    this.dueDateGap = this.dueDateGap ? parseInt(this.dueDateGap) : null;
  }

  castToCents() {
    this.price = this.price ? parseInt(parseFloat(this.price * 100).toFixed(2)) : 0;
    this.fees = this.fees ? parseInt(parseFloat(this.fees * 100).toFixed(2)) : 0;
  }

  serializePaymentTokens() {
    for (const index in this.paymentTokens) {
      if (this.paymentTokens.hasOwnProperty(index)) {
        this.paymentTokens[index] = new SubscriptionPlanPricePaymentToken(this.paymentTokens[index], { serialize: true});
      }
    }
  }

  castToCurrency(object) {
    object.price = object.price ? parseFloat(object.price) / 100 : 0;
    object.fees = object.fees ? parseFloat(object.fees) / 100 : 0;
  }

  castToString(object) {
    object.price = object.price ? new String(object.price).toString() : 0;
    object.fees = object.fees ? new String(object.fees).toString() : 0;
    object.paymentCount = object.paymentCount ? new String(this.paymentCount).toString() : null;
    object.dueDateGap = object.paymentCount ? new String(this.dueDateGap).toString() : null;
    object.dueDateGapType = object.dueDateGapType ? new String(this.dueDateGapType).toString() : null;
  }
}
